<template>
  <td :class="{'reduced' : isHidden}">
    <slot v-if=!isHidden></slot><span v-else>-</span>
  </td>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    ct: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHidden(){
      return this.ct.hiddenField?.[this.field];
    },
  }
};
</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';
  .reduced
    width 20px
    text-align center
</style>