<template>
  <th :class="{'reduced' : isHidden}">
    <span class="eye" @click=toggleHide v-if=!isHidden><span class="fas fa-eye"/></span>
    <span class="eye-slash" v-else @click=toggleHide><span class="fas fa-eye-slash"/></span>
    <span @click="onSort">
      <slot v-if=!isHidden></slot>
      <span :class="[orderClass]" class="order" v-if=!isHidden>{{orderSymbole}}</span>
    </span>
  </th>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    ct: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHidden(){
      return this.ct.hiddenField?.[this.field];
    },
    orderClass() {
      if(this.ct.sortedField === this.field)
        return this.ct.sortOrder === 0 ? 'asc-hover' : '';
      else
        return 'asc-hover';
    },
    orderSymbole() {
      if(this.ct.sortedField === this.field)
        return (this.ct.sortOrder === 1 || this.ct.sortOrder === 0) ? '▼' : '▲';
      else
        return '▼';
    }
  },
  methods: {
    toggleHide(){
      // eslint-disable-next-line vue/no-mutating-props
      this.ct.hiddenField ||= {};
      // eslint-disable-next-line vue/no-mutating-props
      this.ct.hiddenField[this.field] = !this.ct.hiddenField[this.field];
    },
    onSort() {
      // Récupération + initialisation
      let order = this.ct.sortedField === this.field ? this.ct.sortOrder : 0;

      // Boucle sur 0 → 1 → 2 → 0
      order = (order + 1) % 3;

      // Si order est 0, on ne fait rien
      if (order !== 0) {
        // Sinon on trie en fonction de l'ordre
        let localData = [...this.data];
        localData.sort((a, b) => {
          if (a[this.field] < b[this.field]) return order === 1 ? -1 : 1;
          if (a[this.field] > b[this.field]) return order === 1 ? 1 : -1;
          return 0;
        });

        // Émet un événement pour notifier le parent des données triées
        this.$emit("updateData", localData);
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.ct.sortOrder = order
      // eslint-disable-next-line vue/no-mutating-props
      this.ct.sortedField = this.field
    },
  }
};
</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';
th
  cursor pointer
  .eye
    color #849dd7
    margin-right 10px
    cursor pointer
  .eye-slash
    color #849dd7
    cursor pointer
  .order
    margin-left 10px
  .asc-hover
    visibility hidden

  &:hover .asc-hover
    visibility visible
  &.reduced
    width 20px
    text-align center
</style>