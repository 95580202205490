<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Ajouter un client</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <CustomSelectField :content=inputs.status />
        <CustomTextField :content=inputs.name />
        <CustomTextField :content=inputs.description />
        <CustomTextField :content=inputs.phone />
        <CustomTextField :content=inputs.mail />
        <CustomTextAreaField :content=inputs.address />
        <CustomTextField :content=inputs.contact />
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import {NotificationService} from "@/services/NotificationService";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import {ClientController} from "@/controllers/business/ClientController";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import CustomTextAreaField from "@/components/forms/customs/customTextAreaField/CustomTextAreaField.vue";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
import {CLIENT_STATUS_ENUM, CLIENT_STATUS_ENUM_ARRAY} from "@/models/clientStatusEnum";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faExclamation);

  export default {
      name: 'AddClientModal',
      props: ['callback'],
      components: {CustomSelectField, CustomTextAreaField, CustomModal, CustomForm, CustomTextField, CustomButton},
      data() {
          return {
            documentName: "test",
            inputs: {
              status: {label: 'Status', value: '', type: 'select', selectValues: CLIENT_STATUS_ENUM_ARRAY, required: true},
              name: {label: 'Nom', value: '', type: 'text', required: true},
              description: {label: 'Description', value: '', type: 'text'},
              phone: {label: 'Téléphone', value: '', type: 'text'},
              mail: {label: 'Mail', value: '', type: 'text'},
              address: {label: 'Address', value: ''},
              contact: {label: 'Contact', value: '', type: 'text'},
            },
            buttons:{
              submit: {text: "Ajouter", disabled: true}
            },
            isTerminateButtonDisplayed: false,
          };
      },
      methods: {
        openModal() {
          this.$refs.modal.openModal();
          this.buttons.submit.disabled = true;
          this.inputs.status.value = CLIENT_STATUS_ENUM.CONTACT.value;
          this.inputs.name.value = '';
          this.inputs.description.value = '';
          this.inputs.phone.value = '';
          this.inputs.mail.value = '';
          this.inputs.address.value = '';
          this.inputs.contact.value = '';
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => ClientController.post({
            status: this.inputs.status.value,
            name: this.inputs.name.value,
            description: this.inputs.description.value,
            phone: this.inputs.phone.value,
            mail: this.inputs.mail.value,
            address: this.inputs.address.value,
            contact: this.inputs.contact.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Client ajouté");
            this.terminate();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
</style>
