<template>
  <CustomTable ref="table" :callback=reloadCallback :filters=filtersInputs>
    <template v-slot:header-options>
      <button @click="$refs.addClientModal.openModal()" class="button-icon"><span class="fas fa-plus"></span></button>
    </template>
    <template v-slot:table>
      <thead v-if="$refs.table">
        <tr>
          <custom-header :ct=ct :data=data field="createdAt" @updateData=updateData>Date de création</custom-header>
          <custom-header :ct=ct :data=data field="name" @updateData=updateData>Nom</custom-header>
          <custom-header :ct=ct :data=data field="description" @updateData=updateData>Description</custom-header>
          <custom-header :ct=ct :data=data field="address" @updateData=updateData>Address</custom-header>
          <custom-header :ct=ct :data=data field="phone" @updateData=updateData>Téléphone</custom-header>
          <custom-header :ct=ct :data=data field="mail" @updateData=updateData>Mail</custom-header>
          <custom-header :ct=ct :data=data field="contact" @updateData=updateData>Contact</custom-header>
          <custom-header :ct=ct :data=data field="lastContact" @updateData=updateData>Dernier contact</custom-header>
          <custom-header :ct=ct :data=data field="status" @updateData=updateData>Statut</custom-header>
          <custom-header :ct=ct :data=data field="actions" @updateData=updateData>Action</custom-header>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
        <tr v-for="(client, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index" :class="client.status">
          <custom-body :ct=ct field="createdAt">{{(new Date(client.createdAt)).toLocaleDateString("en-GB", formattedDate)}}</custom-body>
          <custom-body :ct=ct field="name">{{client.name}}</custom-body>
          <custom-body :ct=ct field="description">{{client.description}}</custom-body>
          <custom-body :ct=ct field="address">{{client.address}}</custom-body>
          <custom-body :ct=ct field="phone">{{client.phone}}</custom-body>
          <custom-body :ct=ct field="mail">{{client.mail}}</custom-body>
          <custom-body :ct=ct field="contact">{{client.contact}}</custom-body>
          <custom-body :ct=ct field="lastContact">{{client.lastContact ? timeAgo(client.lastContact) : '-'}}</custom-body>
          <custom-body :ct=ct field="status">{{client.status ? CLIENT_STATUS_ENUM[client.status].label : '' }}</custom-body>
          <custom-body :ct=ct field="actions">
            <button class="button-icon-custom" @click="$refs.editClientModal.openModal(client)"><span class="fas fa-pen"></span></button>
          </custom-body>
        </tr>
      </tbody>
      <tbody v-else><tr style="background-color: #40513b14;font-weight: bold"><td colspan="10" style="text-align: center">Aucun client enregitré</td></tr></tbody>
    </template>
  </CustomTable>
  <AddClientModal ref="addClientModal" :callback=loadData />
  <EditClientModal ref="editClientModal" :callback=loadData />
</template>

<script>
    import {ClientController} from "@/controllers/business/ClientController";
    import AddClientModal from "@/components/modals/AddClientModal.vue";
    import EditClientModal from "@/components/modals/EditClientModal.vue";
    import CustomTable from "@/components/CustomTable.vue";
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faMoneyBillTransfer, faList, faChevronLeft, faChevronRight, faPen, faTrashCan, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
    import {CLIENT_STATUS_ENUM} from "@/models/clientStatusEnum";
    import {timeAgo} from "@/utils";
    import CustomHeader from "@/components/tables/CustomHeader.vue";
    import CustomBody from "@/components/tables/CustomBody.vue";
    library.add(faChevronLeft, faPen, faList, faMoneyBillTransfer, faChevronRight, faTrashCan, faEye, faEyeSlash);

    export default {
      name: 'ClientTable',
      computed: {
        CLIENT_STATUS_ENUM() {
          return CLIENT_STATUS_ENUM
        }
      },
      components: {
        CustomBody,
        CustomHeader,
        CustomTable,
        EditClientModal,
        AddClientModal
      },
      data: function () {
        return {
          data: [],
          ct: {},
          formattedDate: {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
          },
          filtersInputs: {
            field: {label: 'Champs', value: '', type: 'select', selectValues: [
                { label: "Date de création", value: "createdAt" },
                { label: "Souscripteur", value: "subscriber" },
              ]},
            sort: {label: 'Trié par', value: '', type: 'select', selectValues: [
                { label: "Date de création", value: "createdAt" },
                { label: "Souscripteur", value: "subscriber" },
              ]},
          }
        }
      },
      mounted(){
        this.loadData();
      },
      methods: {
        timeAgo,
        reloadCallback(){
          window.location.href=`/clients${this.$refs.table.buildURLParams()}`;
        },
        updateData(sortedData) {
          this.data = sortedData;
        },
        async loadData(){
          this.$refs.table.startLoading();
          this.data = [];
          const resp = await ClientController.getAll(this.$refs.table.buildURLParams());
          if(!resp.errors){
            for(let i=0; i < resp.length ;i++){
              // Decrypt needed for some fields
              let client = {};
              client.name = resp[i].name;
              client.description = resp[i].description;
              client.phone = resp[i].phone;
              client.mail = resp[i].mail;
              client.address = resp[i].address;
              client.contact = resp[i].contact;
              client.lastContact = resp[i].lastContact;
              this.data.push(Object.assign(resp[i], client));
            }
          }
          this.$refs.table?.stopLoading();
        }
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .button-icon
    margin-right 10px

  .CONTACT
    background-color #FFD10036 !important
  .QUALIFIED
    background-color #d9800036 !important
  .INTERESTED
    background-color #ff4f0036 !important

</style>